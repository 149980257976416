import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Table, Input, Pagination, Button, Icon, Radio, Select, Slider, Tooltip, Spin, Dropdown, Menu } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import { GDSN_VALUE, MASS_UPDATE_TYPES } from 'util/Constants';
import ScoreCard from './ScoreCard';
import AttributionCharts from './AttributionCharts';
import FnbCharts from './FnbCharts';
import ItemScores from './ItemScores';
import ImageCharts from './ImageCharts';
import FnBMassUpdateModal from './FnBMassUpdate/FnBMassUpdateModal';
import OverallItemScores from './OverallItemScores';
import { createIndexedDbConnection } from '../util/Connection';
import {
  stripFromFilter,
  getValuesFromTwoFields,
  checkStatusWithTwoAndConditions,
  getValueBasedOnTheCondition,
  getFieldKeyOrAssignDefaultValue,
  checkArrayAndLength,
  checkArrayLength,
  checkStatusWithFourOrConditions,
  checkStatusWithTwoAndConditionsWithLengthCheck,
  formatDate,
  prepareOverallFeeExclusionDescription,
  getBcIgAgFromScoreCards
} from '../util/Util';
import RejectCountBanner from './RejectCountBanner';
import AttributeMassEditModal from './AttributeMassEdit/AttributeMassEditModal';
import MassImageUpdate from './ImageMassUpdate/MassImageUpdate';
import PfasMassEditModal from './PfasMassEdit';
import FsmaMassEditModal from './FsmaMassEdit/FsmaMassEditModal';
import RegulatoryComplianceCharts from './RegulatoryComplianceCharts';

const indexedDb = createIndexedDbConnection('item-management');
const RadioGroup = Radio.Group;
const { Option } = Select;

const SCORE_HEADERS = [
  { key: 'scoreTotal', name: 'Total' },
  { key: 'scoreAttribute', name: 'Attribution' },
  { key: 'scoreFnb', name: 'FnB' },
  { key: 'scoreImage', name: 'Images' },
  { key: 'scoreRegulatoryCompliance', name: 'Regulatory Compliance' }
];

const { IMAGE } = MASS_UPDATE_TYPES;

class ItemList extends React.Component {
  constructor(props) {
    super(props);

    const scoresOrder = this.getInitialScoresOrder(props.orderBy);
    this.fnbMassEditRef = React.createRef();
    this.selectedRows = [];

    this.state = {
      tableSearchData: props.tableSearchData || [],
      scoresOrder,
      selectedSupcs: [],
      selectionLimitMessage: ''
    };
  }

  configureIndexedDb = async tableName => {
    try {
      await indexedDb.createObjectStore([{ tableName, indexes: 'supc', indexFieldArray: [] }]);
      const allData = await indexedDb.getAllValue('supplier');
      if (allData && allData.length > 0) {
        const allSupcs = allData.map(eachData => {
          return eachData.supc;
        });
        this.props && this.props.actions.loadAutoSavedFnbComments({ autoSavedData: allSupcs });
      }
    } catch (error) {}
  };

  componentDidMount() {
    this.configureIndexedDb('supplier');
  }

  toggleRejectedFnBFilterBanner = () => {
    const { actions, suvc, rejectedFnbs, fnbRejectFilterOn } = this.props;

    if (fnbRejectFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: null,
        fnbRejectFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: null,
        supc: rejectedFnbs,
        fnbRejectFilterOn: true
      });
    }
  };

  toggleRejectedImageFilterBanner = () => {
    const { actions, suvc, subSuvc, search, rejectedImages, imageRejectFilterOn } = this.props;

    if (imageRejectFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        graphfilter: null,
        imageRejectFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search,
        subSuvc,
        graphfilter: null,
        supc: rejectedImages,
        imageRejectFilterOn: true
      });
    }
  };

  toggleRejectedNutritionFilterBanner = () => {
    const { actions, suvc, subSuvc, search, rejectedNutritions, nutritionRejectFilterOn } = this.props;

    if (nutritionRejectFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        graphfilter: null,
        nutritionRejectFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search,
        subSuvc,
        graphfilter: null,
        supc: rejectedNutritions,
        nutritionRejectFilterOn: true
      });
    }
  };

  toggleRejectedCoreDataFilterBanner = () => {
    const { actions, suvc, subSuvc, search, rejectedCoreData, coreDataRejectFilterOn, rejectedOverall } = this.props;

    if (coreDataRejectFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        graphfilter: null,
        coreDataRejectFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search,
        subSuvc,
        graphfilter: null,
        supc: [...new Set([...rejectedCoreData, ...rejectedOverall])],
        coreDataRejectFilterOn: true
      });
    }
  };

  toggleFeeFilterBanner = () => {
    const { actions, suvc, subSuvc, search, feeExclusionsSupcs, feeFilterOn } = this.props;

    if (feeFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        graphfilter: null,
        feeFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search,
        subSuvc,
        graphfilter: null,
        supc: feeExclusionsSupcs,
        feeFilterOn: true
      });
    }
  };

  toggleAutoSavedFnbBanner = () => {
    const { actions, suvc, subSuvc, search, autoSavedFnb, isAutoSavedFnbFilterOn } = this.props;

    if (isAutoSavedFnbFilterOn) {
      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        graphfilter: null,
        imageRejectFilterOn: false,
        feeFilterOn: false
      });
    } else {
      actions.loadItemList({
        suvc,
        page: 1,
        search,
        subSuvc,
        graphfilter: null,
        supc: autoSavedFnb,
        isAutoSavedFnbFilterOn: true
      });
    }
  };

  isRejectedFnB = supc => {
    return _.includes(this.props.rejectedFnbs, supc);
  };

  isRejectedImage = supc => {
    return _.includes(this.props.rejectedImages, supc);
  };

  isRejectedNutrition = supc => {
    return _.includes(this.props.rejectedNutritions, supc);
  };

  isRejectedCoreData = supc => _.includes(this.props.rejectedCoreData, supc);

  isRejectedOverall = supc => _.includes(this.props.rejectedOverall, supc);

  isHavingAutoSavedFnb = supc => {
    return _.includes(this.props.autoSavedFnb, supc);
  };

  hasScoresFilters = () => {
    let hasScoresFilters = false;

    const scoreHeaders = _.map(SCORE_HEADERS, obj => obj.key);
    const currentSorter = _.get(this.props.orderBy, 'param', null);
    if (_.includes(scoreHeaders, currentSorter)) {
      hasScoresFilters = true;
    }

    _.forEach(this.state.tableSearchData, obj => {
      if (_.includes(scoreHeaders, obj.param)) {
        hasScoresFilters = true;
      }
    });

    return hasScoresFilters;
  };

  handleFilterChanges = (pagination, filters, sorter) => {
    const { actions, suvc, brandGroupFilters, graphFilter } = this.props;
    const field = _.get(sorter, 'field', null);
    let order = _.get(sorter, 'order', false);
    const tableFilters = [];

    if (order === 'ascend') {
      order = 'asc';
    } else if (order === 'descend') {
      order = 'desc';
    }

    _.forIn(filters, (value, key) => {
      if (!_.isEmpty(value)) {
        tableFilters.push({ param: key, val: value });
      }
    });

    const { tableSearchData } = this.state;

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: graphFilter,
      tableFilters: _.isEmpty(tableFilters) ? null : tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy: field ? { param: field, val: order } : null
    });
  };

  clearAllFilters = () => {
    const { actions, suvc } = this.props;

    this.applyFilterForGraph({
      brand: null,
      bc: null,
      ig: null,
      ag: null,
      sysco: null
    });

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: null,
      tableFilters: null,
      tableSearchData: null,
      brandGroupFilters: [],
      orderBy: null
    });
  };

  getSortOrder = columnName => {
    const field = _.get(this.props.orderBy, 'param', null);
    let order = _.get(this.props.orderBy, 'val', false);

    if (order === 'asc') {
      order = 'ascend';
    } else if (order === 'desc') {
      order = 'descend';
    }

    if (field && field === columnName) {
      return order;
    }

    return false;
  };

  getInitialScoresOrder = orderBy => {
    let scoresOrder = {};

    if (orderBy) {
      const target = _.find(SCORE_HEADERS, obj => obj.key === orderBy.param);

      if (target) {
        scoresOrder = orderBy;
      }
    }

    return scoresOrder;
  };

  getFormattedBrands = () => {
    const brands = _.get(this.props.suvcDetails, 'brands', []);
    brands.sort();
    const formatted = [];

    _.forEach(brands, elem => {
      formatted.push({ text: elem, value: elem });
    });
    return formatted;
  };

  getSupplierAccountFilters = () => {
    const subSuvcFilters = [];
    const { subSuvcFilters: propsSubSuvcFilters } = this.props;

    const sortedData = _.sortBy(propsSubSuvcFilters, ['name']);

    _.forEach(sortedData, eachSubSuvc => {
      const { suvc, name } = eachSubSuvc;
      if (suvc) {
        const text = `${suvc}${name !== '' ? ` - ${name}` : ''}`;
        subSuvcFilters.push({ text, value: suvc });
      }
    });
    return subSuvcFilters;
  };

  getFeeExclusionsBySupc = (suvc, supc, details, isFetching) => {
    if (supc && _.isEmpty(details) && !isFetching) {
      this.props.actions.fetchFeeExclusionsBySupc(suvc, supc);
    }
  };

  onTableSearch = () => {
    const { actions, suvc, tableFilters, brandGroupFilters, orderBy, graphFilter } = this.props;

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: graphFilter,
      tableFilters,
      tableSearchData: this.state.tableSearchData,
      brandGroupFilters,
      orderBy
    });
  };

  onTableSearchInputChange = (columnName, value) => {
    let clone = [...this.state.tableSearchData];
    let target = _.find(clone, obj => columnName === obj.param);
    let scoresHeaders = _.map(SCORE_HEADERS, obj => obj.key);

    if (!_.isEmpty(value)) {
      if (target) {
        target.val = [value];

        if (['materialDescription', 'supc', 'manufactProdCode', 'gtin'].includes(columnName)) {
          target.operator = 'like';
        } else if (scoresHeaders.includes(columnName)) {
          let from = getValuesFromTwoFields(value[0], 0);
          let to = value[1];
          target.val = [from, to];
          target.operator = 'range';
        }
      } else if (['materialDescription', 'supc', 'manufactProdCode', 'gtin'].includes(columnName)) {
        clone.push({ param: columnName, val: [value], operator: 'like' });
      } else {
        if (['materialDescription', 'supc', 'manufactProdCode', 'gtin'].includes(columnName)) {
          clone.push({ param: columnName, val: [value], operator: 'like' });
        } else if (scoresHeaders.includes(columnName)) {
          let from = getValuesFromTwoFields(value[0], 0);
          let to = value[1];
          clone.push({ param: columnName, val: [from, to], operator: 'range' });
        } else {
          clone.push({ param: columnName, val: [value] });
        }
      }
    } else {
      clone = _.filter(clone, obj => columnName !== obj.param);
    }

    this.setState({ tableSearchData: clone });
  };

  onClearTableSearch = columnName => {
    const { actions, suvc, tableFilters, brandGroupFilters, orderBy, graphFilter } = this.props;
    const clone = [...this.state.tableSearchData];
    const result = _.filter(clone, obj => columnName !== obj.param);

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: graphFilter,
      tableFilters,
      tableSearchData: result,
      brandGroupFilters,
      orderBy
    });
  };

  applyFilterForGraph = filter => {
    const {
      actions,
      scorecards: { filterForGraphs }
    } = this.props;
    const updatedFilter = { ...filterForGraphs, ...filter };
    actions.changeFilterForGraph({ filter: { ...updatedFilter } });
  };

  applyAgFilterForGraph = newAg => {
    const {
      scorecards: { filterForGraphs }
    } = this.props;
    this.applyFilterForGraph({
      bc: filterForGraphs.bc,
      ig: filterForGraphs.ig,
      ag: newAg
    });
    if (newAg !== null) {
      this.filterGraph([{ param: 'ag', val: newAg }], ['ag']);
    } else {
      this.filterGraph(null, ['ag']);
    }
  };

  applyBcFilterForGraph = newBc => {
    this.applyFilterForGraph({
      bc: newBc,
      ig: null,
      ag: null
    });
    if (newBc !== null) {
      this.filterGraph([{ param: 'bc', val: newBc }], ['bc', 'ig', 'ag']);
    } else {
      this.filterGraph(null, ['bc', 'ig', 'ag']);
    }
  };

  applyIgFilterForGraph = newIg => {
    const {
      scorecards: { filterForGraphs }
    } = this.props;
    this.applyFilterForGraph({
      bc: filterForGraphs.bc,
      ig: newIg,
      ag: null
    });
    if (newIg !== null) {
      this.filterGraph([{ param: 'ig', val: newIg }], ['ig', 'ag']);
    } else {
      this.filterGraph(null, ['ig', 'ag']);
    }
  };

  filterGraph = (add, remove, brandGroup = null) => {
    const { graphFilter, actions, suvc, tableFilters, orderBy, tableSearchData, brandGroupFilters } = this.props;
    let filterStr = null;

    let currentGraphFilter = null;

    if (graphFilter) {
      currentGraphFilter = JSON.parse(graphFilter);
    }

    if (remove && remove.length > 0) {
      currentGraphFilter = stripFromFilter(remove, graphFilter);
    }

    if (add) {
      currentGraphFilter = [...currentGraphFilter, ...add];
    }

    if (currentGraphFilter) {
      filterStr = JSON.stringify(currentGraphFilter);
    }

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      subSuvc: null,
      graphfilter: filterStr,
      tableFilters,
      orderBy,
      brandGroupFilters: brandGroup ? (brandGroup === 'all' ? [] : [brandGroup]) : brandGroupFilters,
      tableSearchData
    });
  };

  removeFilterFromGraph = () => {
    const {
      actions,
      scorecards: { filterForGraphs }
    } = this.props;
    actions.changeFilterForGraph({ filter: { ...filterForGraphs, sysco: null } });
  };

  openAttributeMassEditModal = async () => {
    const { actions, scorecards } = this.props;
    const { taxonomy, bc, ig, ag } = getBcIgAgFromScoreCards(scorecards);

    actions.toggleAttributeMassEditModal({
      showModal: true,
      taxonomy,
      bc,
      ig,
      ag
    });
  };

  openPfasMassEditModal = async () => {
    const { actions, scorecards } = this.props;
    const { taxonomy, bc, ig, ag } = getBcIgAgFromScoreCards(scorecards);

    actions.togglePfasMassEditModal({
      showModal: true,
      taxonomy,
      bc,
      ig,
      ag
    });
  };

  onClickFsmaMassEdit = () => {
    const { selectedSupcs } = this.state;
    if (selectedSupcs.length < 1) {
      this.setState({ selectionLimitMessage: 'Select at least one item' });
    } else if (selectedSupcs.length > 15) {
      this.setState({ selectionLimitMessage: 'Max limit 15 items' });
    } else {
      this.props.actions.toggleFsmaMassEditModal();
    }
  };

  onChangeBrandGroupdFilters = brandGroup => {
    if (brandGroup === 'sysco') {
      this.applyFilterForGraph({
        sysco: '1'
      });
      this.filterGraph([{ param: 'sysco', val: '1' }], ['sysco'], brandGroup);
    } else if (['packer', 'national'].includes(brandGroup)) {
      this.filterGraph(null, ['sysco'], brandGroup);
    } else {
      this.removeFilterFromGraph();
      this.filterGraph(null, ['sysco'], 'all');
    }
  };

  onChangeScoresOrder = (field, value) => {
    this.setState(prevState => {
      const clone = { ...prevState.scoresOrder };

      if (field === 'sorter') {
        clone.param = value;

        if (_.isEmpty(clone.val)) {
          clone.val = 'asc';
        }
      } else if (field === 'order') {
        clone.val = value;
      }
      return { scoresOrder: clone };
    });
  };

  onScoresFilterMenuVisibleChange = visible => {
    if (!visible) {
      const scoresOrder = this.getInitialScoresOrder(this.props.orderBy);
      const tableSearchData = this.props.tableSearchData || [];
      this.setState({ scoresOrder, tableSearchData });
    }
  };

  onFilterScores = () => {
    let { actions, suvc, tableFilters, brandGroupFilters, orderBy, graphFilter } = this.props;

    const { scoresOrder, tableSearchData } = this.state;

    if (!_.isEmpty(scoresOrder.param)) {
      orderBy = scoresOrder;
    }

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: graphFilter,
      tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy
    });
  };

  onResetScoresFilters = () => {
    let { actions, suvc, tableFilters, brandGroupFilters, orderBy, graphFilter } = this.props;

    const scoreHeaders = _.map(SCORE_HEADERS, obj => obj.key);
    const currentSorter = _.get(orderBy, 'param', null);
    const tableSearchData = [];

    _.forEach(this.state.tableSearchData, obj => {
      if (!_.includes(scoreHeaders, obj.param)) {
        tableSearchData.push(obj);
      }
    });

    if (_.includes(scoreHeaders, currentSorter)) {
      orderBy = null;
    }

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      graphfilter: graphFilter,
      tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy
    });
  };

  onClickMassImageEdit = type => {
    const { selectedSupcs } = this.state;
    if (selectedSupcs.length < 2) {
      this.setState({ selectionLimitMessage: 'Select at least two items' });
    } else if (selectedSupcs.length > 10) {
      this.setState({ selectionLimitMessage: 'Max limit 10 items' });
    } else {
      this.props.actions.massUpdateSelectionChanged({ massUpdateType: type });
      this.props.actions.closeMassUpdateImageOverlay({});
    }
  };

  onClickMassFnBEdit = () => {
    const { selectedSupcs } = this.state;
    if (selectedSupcs.length < 1) {
      this.setState({ selectionLimitMessage: 'Select at least one item' });
    } else if (selectedSupcs.length > 10) {
      this.setState({ selectionLimitMessage: 'Max limit 10 items' });
    } else {
      this.fnbMassEditRef.current.openModal();
    }
  };

  renderTableSearch = columnName => {
    const refName = `ref-${columnName}`;
    const target = _.find(this.state.tableSearchData, obj => columnName === obj.param);
    const value = _.get(target, 'val[0]', '');

    return (
      <div className="table-search-dropdown">
        <Input
          className="table-search-input"
          ref={ref => (this[refName] = ref)}
          value={value}
          onChange={e => this.onTableSearchInputChange(columnName, e.target.value)}
          onPressEnter={this.onTableSearch}
        />
        <div className="table-search-btn-wrapper">
          <Button className="table-search-btn" type="primary" onClick={() => this.onTableSearch(columnName)}>
            Search
          </Button>
          <Button className="table-search-btn-reset" onClick={() => this.onClearTableSearch(columnName)}>
            Reset
          </Button>
        </div>
      </div>
    );
  };

  renderScoresFilterMenu = () => {
    const field = _.get(this.state.scoresOrder, 'param', null);
    const order = _.get(this.state.scoresOrder, 'val', null);

    return (
      <div className="scores-filter-dropdown">
        <div className="scores-filter-title">SORT</div>
        <div className="filter-dropdown-title">Sort by</div>
        <RadioGroup
          className="scores-order-radio-grp"
          value={field}
          onChange={e => this.onChangeScoresOrder('sorter', e.target.value)}
        >
          {SCORE_HEADERS.map(header => (
            <Radio key={header.key} value={header.key}>
              {header.name}
            </Radio>
          ))}
        </RadioGroup>

        <div className="filter-dropdown-title">Sort order</div>
        <RadioGroup
          className="scores-order-radio-grp"
          value={order}
          disabled={_.isEmpty(this.state.scoresOrder)}
          onChange={e => this.onChangeScoresOrder('order', e.target.value)}
        >
          <Radio value="asc">Ascending</Radio>
          <Radio value="desc">Descending</Radio>
          <Radio value={false}>None</Radio>
        </RadioGroup>

        <div className="filter-divider" />
        <div className="scores-filter-title">FILTER</div>
        {SCORE_HEADERS.map(header => {
          let target = _.find(this.state.tableSearchData, obj => header.key === obj.param);
          let value = _.get(target, 'val', []);
          return (
            <div key={header.key} className="scores-search-wrapper">
              <div className="scores-search-lbl">{header.name}</div>
              <Slider
                range
                value={value}
                step={5}
                onChange={value => this.onTableSearchInputChange(header.key, value)}
              />
            </div>
          );
        })}
        <div className="scores-search-btn-wrapper">
          <Button className="scores-search-btn" type="primary" onClick={this.onFilterScores}>
            Apply
          </Button>
          <Button className="reset" onClick={this.onResetScoresFilters}>
            Reset
          </Button>
        </div>
      </div>
    );
  };

  renderBrandFilters = () => {
    const {
      scorecards: {
        filterForGraphs: { bc, ig, ag },
        taxonomy
      },
      isInternal,
      suvc,
      rejectedFnbs
    } = this.props;
    const brandGroupFilter = _.get(this.props, 'brandGroupFilters[0]', null);

    let businessCenters = Object.getOwnPropertyNames(taxonomy);
    let itemGroups = [];
    let attributeGroups = [];

    if (taxonomy) {
      if (bc !== null) {
        itemGroups = Object.getOwnPropertyNames(taxonomy[bc]);
        if (ig !== null) {
          attributeGroups = Object.getOwnPropertyNames(taxonomy[bc][ig]);
        }
      }
    }

    businessCenters = businessCenters && [...businessCenters].sort();
    itemGroups = itemGroups && [...itemGroups].sort();
    attributeGroups = attributeGroups && [...attributeGroups].sort();

    return (
      <div className="brand-filters-wrapper item-list-taxo-filters">
        <div className="filters-left">
          {!isInternal && this.renderMassEditButton()}
          {!_.isEmpty(this.state.selectionLimitMessage) && (
            <div className="mass-update-btn-msg">{this.state.selectionLimitMessage}</div>
          )}
        </div>
        <div className="filters-right">
          <div className="score-filters-row">
            <Select defaultValue={bc} onChange={businessCenter => this.applyBcFilterForGraph(businessCenter)}>
              <Option value={null} key="0">
                All Business Centers
              </Option>
              {businessCenters.map(bcOption => {
                return (
                  <Option value={bcOption} key={bcOption}>
                    {bcOption}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={ig}
              disabled={itemGroups.length === 0}
              onChange={itemGroup => this.applyIgFilterForGraph(itemGroup)}
            >
              <Option value={null} key="0">
                All Item Groups
              </Option>
              {itemGroups.map(igOption => {
                return (
                  <Option value={igOption} key={igOption}>
                    {igOption}
                  </Option>
                );
              })}
            </Select>
            <Select
              defaultValue={ag}
              disabled={attributeGroups.length === 0}
              onChange={attributeGroup => this.applyAgFilterForGraph(attributeGroup)}
            >
              <Option value={null} key="0">
                All Attribute Groups
              </Option>
              {attributeGroups.map(agOption => {
                return (
                  <Option value={agOption} key={agOption}>
                    {agOption}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="table-brand-filter-wrapper">
            <RadioGroup
              className="table-brand-filter comments-brand-filter-set"
              value={brandGroupFilter}
              onChange={e => this.onChangeBrandGroupdFilters(e.target.value)}
            >
              <Radio value="sysco">Sysco</Radio>
              <Radio value="packer">Packer</Radio>
              <Radio value="national">National</Radio>
              <Radio value={null}>All</Radio>
            </RadioGroup>
          </div>

          <Button className="clear-all-filters" onClick={this.clearAllFilters}>
            Clear Filters
          </Button>
        </div>
      </div>
    );
  };

  renderBrandsColumn = (brandName, item) => {
    let symbol = '';
    let symbolTitle = '';

    if (item.brandCode === 'PACKER') {
      symbol = 'P';
      symbolTitle = 'Packer Brand';
    } else if (item.syscoBrand === 'Y') {
      symbol = 'S';
      symbolTitle = 'Sysco Brand';
    }

    return (
      <div className="ellipsed brands-cell" title={brandName}>
        {!_.isEmpty(symbol) && (
          <div className={`brand-indicator ${symbol.toLowerCase()}brand`} title={symbolTitle}>
            {symbol}
          </div>
        )}
        <div className="brand-name">{brandName}</div>
      </div>
    );
  };

  cancelOverlay = () => {
    this.setState({ isMessageOverlayOn: false });
  };

  resetScoreFilters = () => {
    const { actions, suvc, tableFilters, brandGroupFilters, orderBy, tableSearchData } = this.props;

    this.applyFilterForGraph({
      brand: null,
      bc: null,
      ig: null,
      ag: null,
      sysco: null
    });

    actions.loadItemList({
      suvc,
      page: 1,
      search: '',
      subSuvc: null,
      graphfilter: null,
      tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy
    });
  };

  getFeeExclusionTooltip = (feeExclusions, itemMasterData) => {
    const nonReject = _.filter(feeExclusions, obj => /^M\d/.test(obj.reasonCode) && obj.isActive === 1);
    const latestExclusion = _.maxBy(nonReject, obj => moment(obj.validUntil));

    if (!latestExclusion) return null;

    let fields = [];
    const lastSalesDate = _.get(itemMasterData, 'lastObligDate', null);
    const firstPoDate = _.get(itemMasterData, 'firstPoDate', null);
    const lastPoDate = _.get(itemMasterData, 'lastPoDate', null);
    const validUntil = _.get(latestExclusion, 'validUntil', null);
    const isPoLatest = moment(lastPoDate).isAfter(lastSalesDate);

    if (_.includes(['M6'], latestExclusion.reasonCode)) {
      fields.push('firstPoDate');
    } else if (_.includes(['M7', 'M8'], latestExclusion.reasonCode)) {
      if (isPoLatest) {
        fields.push('lastPoDate');
      } else {
        fields.push('lastObligDate');
      }
    }

    return (
      <div className="indication-dot-tooltip-container">
        <ul className="indication-dot-tooltip-content">
          <div>{latestExclusion.description}</div>
          {_.includes(fields, 'lastObligDate') && <li>Last Sale Issue Date: {formatDate(lastSalesDate)}</li>}
          {_.includes(fields, 'firstPoDate') && <li>First PO Issue Date: {formatDate(firstPoDate)}</li>}
          {_.includes(fields, 'lastPoDate') && <li>Last PO Issue Date: {formatDate(lastPoDate)}</li>}
          {latestExclusion.reasonCode !== 'M12' && <li>Enhance Content by: {formatDate(validUntil)}</li>}
        </ul>
      </div>
    );
  };

  // In case if a single type of exclusion has multiple records, get the latest
  groupExclusionsByDate = exclusions => {
    const latest = _.maxBy(exclusions, obj => moment(obj.createdAt));
    return [latest];
  };

  getRejectionTooltipContent = (type, feeExclusions) => {
    const fnb = _.filter(feeExclusions, obj => obj.reasonCode === 'FNB');
    const img = _.filter(feeExclusions, obj => obj.reasonCode === 'IMAGE');
    const core = _.filter(feeExclusions, obj => obj.reasonCode === 'CORE');
    const enut = _.filter(feeExclusions, obj => obj.reasonCode === 'NUTRITION');
    const overall = _.filter(feeExclusions, obj => obj.reasonCode === 'OVERALL');

    let enhanceByLabel = 'Enhance Content by';
    let data = [];

    if (type === 'fnb') {
      enhanceByLabel = 'Enhance FnB by';
      data = fnb;
      if (_.isEmpty(data)) data.push({ description: 'FnB Statement (or parts of) has been rejected' });
    } else if (type === 'img') {
      enhanceByLabel = 'Enhance Image by';
      data = img;
      if (_.isEmpty(data)) data.push({ description: 'One or more images have been rejected' });
    } else if (type === 'core') {
      enhanceByLabel = 'Enhance Core Data by';
      data = core;
      if (_.isEmpty(data)) data.push({ description: 'One or more Core Data attributes have been rejected' });
    } else if (type === 'enut') {
      enhanceByLabel = 'Enhance Nutrition by';
      data = enut;
      if (_.isEmpty(data)) data.push({ description: 'One or more Nutrition attributes have been rejected' });
    } else if (type === 'overall') {
      enhanceByLabel = 'Enhance Content by';
      data = prepareOverallFeeExclusionDescription(overall);
      if (_.isEmpty(data)) data.push({ description: 'One or more Overall Feedbacks have been rejected' });
    }

    data = this.groupExclusionsByDate(data);
    return { enhanceByLabel, data };
  };

  filterTooltipContentWithOverall = (enhanceByLabel, { createdAt, validUntil, isActive, reasonCode }) => {
    if (reasonCode === 'OVERALL') {
      return (
        <li className="innner-list">
          {enhanceByLabel}: {formatDate(validUntil)}
        </li>
      );
    }
    return (
      <>
        <ul className="indication-dot-tooltip-content">
          {createdAt && <li className="innner-list">Rejection Date: {formatDate(createdAt)}</li>}
          {validUntil && (
            <li className="innner-list">
              {enhanceByLabel}: {isActive ? formatDate(validUntil) : 'Past due by over 30 days'}
            </li>
          )}
        </ul>
      </>
    );
  };

  getRejectionTooltip = (feeExclusions, rejectedTypes) => {
    if (!feeExclusions) return null;

    const body = _.map(rejectedTypes, type => {
      const { enhanceByLabel, data } = this.getRejectionTooltipContent(type, feeExclusions);
      return (
        <React.Fragment key={type}>
          {_.map(data, ({ id, description, ...rest }) => {
            return (
              <ul key={id || description} className="indication-dot-tooltip-content">
                <li>{description}</li>
                {this.filterTooltipContentWithOverall(enhanceByLabel, rest)}
              </ul>
            );
          })}
        </React.Fragment>
      );
    });

    return <div className="indication-dot-tooltip-container">{body}</div>;
  };

  getFnbAutoSaveTooltip = () => {
    return (
      <div className="indication-dot-tooltip-container">
        <div className="title">UNSAVED FNB</div>
      </div>
    );
  };

  getTypesOfRejections = supc => {
    let rejects = [];
    this.isRejectedFnB(supc) && rejects.push('fnb');
    this.isRejectedImage(supc) && rejects.push('img');
    this.isRejectedNutrition(supc) && rejects.push('enut');
    this.isRejectedCoreData(supc) && rejects.push('core');
    this.isRejectedOverall(supc) && rejects.push('overall');

    return rejects;
  };

  renderIndicationDot = (type, item) => {
    let antIcon = <LoadingOutlined style={{ fontSize: 14, color: '#ffffff' }} spin />;
    let tooltip = null;
    let indicatorColor = '';
    let { suvc, supc, itemMasterData, feeExclusions, isFetchingFeeExclusionDetails } = item;

    const rejectedTypes = this.getTypesOfRejections(supc);
    const showBlueDot = type === 'fee' && _.includes(this.props.feeExclusionsSupcs, supc);
    const showFnbAutoSave = type === 'autosavefnb' && this.isHavingAutoSavedFnb(supc);
    const showRedDot = type === 'rejects' && !_.isEmpty(rejectedTypes);

    const onMouseOver = () => {
      if (showBlueDot || showRedDot) {
        this.getFeeExclusionsBySupc(suvc, supc, feeExclusions, isFetchingFeeExclusionDetails);
      }
    };

    if (showBlueDot) {
      indicatorColor = 'blue';
      tooltip = this.getFeeExclusionTooltip(feeExclusions, itemMasterData);
    } else if (showRedDot) {
      indicatorColor = 'red';
      tooltip = this.getRejectionTooltip(feeExclusions, rejectedTypes);
    } else if (showFnbAutoSave) {
      indicatorColor = 'orange';
      tooltip = this.getFnbAutoSaveTooltip();
    }

    if (showBlueDot || showRedDot || showFnbAutoSave) {
      return (
        <Tooltip overlayClassName={'indication-dot-tooltip'} title={tooltip ? tooltip : <Spin indicator={antIcon} />}>
          <div className="indication-dot-wrapper">
            <div className={`indication-dot bg-${indicatorColor}`} onMouseOver={onMouseOver} />
          </div>
        </Tooltip>
      );
    }
  };

  renderMassEditButton = () => {
    const { actions } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key={'fnb'} onClick={this.onClickMassFnBEdit}>
          Features & Benefits
        </Menu.Item>
        <Menu.Item key={'images'} onClick={() => this.onClickMassImageEdit(IMAGE)}>
          Images
        </Menu.Item>
        <Menu.Item key={'attributes'} onClick={this.openAttributeMassEditModal}>
          Product Attributes
        </Menu.Item>
        <Menu.SubMenu title="Regulatory Compliance">
          <Menu.Item key={'pfas'} onClick={this.openPfasMassEditModal}>
            PFAS
          </Menu.Item>
          <Menu.Item key={'fsma'} onClick={this.onClickFsmaMassEdit}>
            FSMA 204
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );

    return (
      <Dropdown className="mass-edit-dropdown" overlay={menu} trigger={['click']}>
        <Button>
          Mass Edit <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  render() {
    let {
      suvc,
      list,
      actions,
      selectedSupc,
      search,
      page,
      recordCount,
      suvcDetails,
      subSuvc,
      scorecards,
      summaryItems,
      graphFilter,
      rejectedFnbs,
      rejectedImages,
      overallSummary,
      fetchingOverallSummary,
      fnbRejectFilterOn,
      imageRejectFilterOn,
      feeFilterOn,
      isAutoSavedFnbFilterOn,
      tableSearchData,
      tableFilters,
      orderBy,
      brandGroupFilters,
      isFiltersComplex,
      feeExclusionsSupcs,
      rejectedNutritions,
      nutritionRejectFilterOn,
      rejectedCoreData,
      rejectedOverall,
      coreDataRejectFilterOn
    } = this.props;
    subSuvc = getValuesFromTwoFields(subSuvc, null);

    const supcSearchTerm = _.find(tableSearchData, obj => obj.param === 'supc');
    const materialDescriptionSearchTerm = _.find(tableSearchData, obj => obj.param === 'materialDescription');
    const manufactProdCodeSearchTerm = _.find(tableSearchData, obj => obj.param === 'manufactProdCode');
    const gtinSearchTerm = _.find(tableSearchData, obj => obj.param === 'gtin');

    const targetBrandName = _.find(this.props.tableFilters, obj => obj.param === 'brandName');
    const filteredBrands = getFieldKeyOrAssignDefaultValue(targetBrandName, 'val', []);

    const targetSupplierAccount = _.find(this.props.tableFilters, obj => obj.param === 'suvc');
    const filteredSupplierAccount = getFieldKeyOrAssignDefaultValue(targetSupplierAccount, 'val', []);

    let columns = [
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc',
        width: 100,
        filterDropdown: () => this.renderTableSearch('supc'),
        filterIcon: (
          <Icon
            type="search"
            style={{ color: getValueBasedOnTheCondition(!_.isEmpty(supcSearchTerm), '#108ee9', '#aaa') }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('supc')
      }
    ];

    if (checkStatusWithTwoAndConditions(summaryItems, summaryItems.length)) {
      columns = [
        ...columns,
        ...[
          {
            title: (
              <div className="list-item-scores score-header">
                <div className="list-item-score overall">
                  <span>Total</span>
                </div>
                <div className="list-item-score">
                  <span title="Attribution">Attrib...</span>
                </div>
                <div className="list-item-score">
                  <span>FnB</span>
                </div>
                <div className="list-item-score">
                  <span>Images</span>
                </div>
                <div className="list-item-score">
                  <span title="Regulatory Compliance">RC</span>
                </div>
              </div>
            ),
            dataIndex: 'supc',
            key: 'supc2',
            width: 80,
            filterIcon: (
              <Icon
                type="search"
                style={{ color: getValueBasedOnTheCondition(this.hasScoresFilters(), '#108ee9', '#aaa') }}
              />
            ),
            filterDropdown: () => this.renderScoresFilterMenu(),
            render: (supc, record) => (
              <ItemScores supc={supc} summaryItems={summaryItems} isPacker={record.brandCode === 'PACKER'} />
            ),
            onFilterDropdownVisibleChange: this.onScoresFilterMenuVisibleChange,
            align: 'right'
          }
        ]
      ];
    }

    columns = [
      ...columns,
      ...[
        {
          title: 'Description',
          dataIndex: 'materialDescription',
          key: 'materialDescription',
          width: 'auto',
          filterDropdown: () => this.renderTableSearch('materialDescription'),
          filterIcon: (
            <Icon
              type="search"
              style={{
                color: getValueBasedOnTheCondition(!_.isEmpty(materialDescriptionSearchTerm), '#108ee9', '#aaa')
              }}
            />
          ),
          sorter: true,
          sortOrder: this.getSortOrder('materialDescription'),
          render: (desc, item) => {
            return (
              <div>
                {this.renderIndicationDot('fee', item)}
                {this.renderIndicationDot('autosavefnb', item)}
                {this.renderIndicationDot('rejects', item)}
                <span>{desc && desc.toUpperCase()}</span>
              </div>
            );
          }
        },
        {
          title: 'Brand',
          dataIndex: 'brandName',
          key: 'brandName',
          width: 130,
          filters: this.getFormattedBrands(),
          filteredValue: filteredBrands,
          sorter: true,
          sortOrder: this.getSortOrder('brandName'),
          render: (brandName, item) => this.renderBrandsColumn(brandName, item)
        }
      ]
    ];

    if (checkStatusWithTwoAndConditionsWithLengthCheck(suvcDetails.subSuvcs)) {
      columns = [
        ...columns,
        ...[
          {
            title: 'Supplier Account',
            dataIndex: 'suvc',
            key: 'suvc',
            width: 160,
            filters: this.getSupplierAccountFilters(),
            filteredValue: filteredSupplierAccount,
            sorter: true,
            sortOrder: this.getSortOrder('suvc'),
            render: (suvc, item) => (
              <div className="ellipsed allcap" title={`${suvc} - ${item.trueVendorName}`}>
                {`${suvc} - ${item.trueVendorName}`}
              </div>
            )
          }
        ]
      ];
    }

    columns = [
      ...columns,
      ...[
        {
          title: 'MPC',
          dataIndex: 'manufactProdCode',
          key: 'manufactProdCode',
          width: 130,
          filterDropdown: () => this.renderTableSearch('manufactProdCode'),
          filterIcon: (
            <Icon
              type="search"
              style={{ color: getValueBasedOnTheCondition(!_.isEmpty(manufactProdCodeSearchTerm), '#108ee9', '#aaa') }}
            />
          ),
          sorter: true,
          sortOrder: this.getSortOrder('manufactProdCode')
        },
        {
          title: 'GDSN',
          dataIndex: 'gdsn',
          key: 'gdsn',
          width: 60,
          render: gdsn => <span>{getValueBasedOnTheCondition(gdsn === GDSN_VALUE, '✓', '')}</span>,
          align: 'center'
        },
        {
          title: 'GTIN',
          dataIndex: 'gtin',
          key: 'gtin',
          width: 180,
          filterDropdown: () => this.renderTableSearch('gtin'),
          filterIcon: (
            <Icon
              type="search"
              style={{ color: getValueBasedOnTheCondition(!_.isEmpty(gtinSearchTerm), '#108ee9', '#aaa') }}
            />
          ),
          sorter: true,
          sortOrder: this.getSortOrder('gtin')
        }
      ]
    ];

    const onRowHandler = item => {
      if (this.props.isInternal) {
        actions.loadSupc({ stepId: item.stepId, supc: item.supc, item, suvc });
      } else {
        actions.itemDetails({ stepId: item.stepId, supc: item.supc, item, suvc });
        actions.itemCooDetails({ supc: item.supc, syscoBrand: item.syscoBrand, gdsn: item.gdsn });
      }
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const supcs = _.map(selectedRows, row => row.supc);
        this.selectedRows = selectedRows;
        this.setState({ selectedSupcs: supcs });
      }
    };

    const handlePagination = page => {
      const {
        actions,
        suvc,
        tableFilters,
        brandGroupFilters,
        orderBy,
        graphFilter,
        feeFilterOn,
        fnbRejectFilterOn,
        imageRejectFilterOn,
        isFiltersComplex,
        nutritionRejectFilterOn,
        autoSavedFnb
      } = this.props;
      let supc = null;

      if (fnbRejectFilterOn) {
        supc = rejectedFnbs;
      } else if (imageRejectFilterOn) {
        supc = rejectedImages;
      } else if (coreDataRejectFilterOn) {
        supc = [...new Set([...rejectedCoreData, ...rejectedOverall])];
      } else if (nutritionRejectFilterOn) {
        supc = rejectedNutritions;
      } else if (isAutoSavedFnbFilterOn) {
        supc = autoSavedFnb;
      } else if (feeFilterOn) {
        supc = feeExclusionsSupcs;
      }

      actions.loadItemList({
        suvc,
        page,
        search,
        subSuvc,
        graphfilter: graphFilter,
        supc,
        tableFilters,
        tableSearchData: this.state.tableSearchData,
        brandGroupFilters,
        orderBy,
        coreDataRejectFilterOn,
        feeFilterOn,
        fnbRejectFilterOn,
        imageRejectFilterOn,
        isAutoSavedFnbFilterOn,
        isFiltersComplex,
        nutritionRejectFilterOn
      });
    };

    const fnbRejectCount = rejectedFnbs.length;
    const imageRejectCount = rejectedImages.length;
    const nutritionRejectCount = rejectedNutritions.length;
    const coreDataRejectCount = [...new Set([...rejectedCoreData, ...rejectedOverall])].length;

    let filteredScoreCards = _.get(scorecards, 'scores', []);

    if (!this.props.isInternal) {
      filteredScoreCards = filteredScoreCards.filter(score => !['overall', 'contact'].includes(score.cardType));
    }

    const subSuvcs = [..._.get(suvcDetails, 'subSuvcs', [])];

    subSuvcs.sort((a, b) => {
      const aValue = getValuesFromTwoFields(a.name, '');
      const bValue = getValuesFromTwoFields(b.name, '');

      return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });
    });

    let feeExCount = getValueBasedOnTheCondition(
      checkArrayAndLength(feeExclusionsSupcs),
      checkArrayLength(feeExclusionsSupcs),
      0
    );

    const areNotificationBannersAvailable = checkStatusWithFourOrConditions(
      checkArrayAndLength(this.props.autoSavedFnb),
      imageRejectCount > 0,
      fnbRejectCount > 0,
      feeExCount > 0
    );

    const handleRenderingScoreCards = () => {
      switch (scorecards.activeChart) {
        case 'overall':
          return (
            <OverallItemScores
              summaryItems={overallSummary}
              actions={actions}
              suvc={suvc}
              graphFilter={graphFilter}
              filterForGraphs={scorecards.filterForGraphs}
              taxonomy={scorecards.taxonomy}
              brands={scorecards.brands}
              fetchingOverallSummary={fetchingOverallSummary}
              tableFilters={tableFilters}
              orderBy={orderBy}
              brandGroupFilters={brandGroupFilters}
              tableSearchData={tableSearchData}
              isComplexRendering={isFiltersComplex}
              clearAllFilters={this.clearAllFilters}
              isHiddingFilters
            />
          );
        case 'attribution':
          return (
            <AttributionCharts
              summaryItems={summaryItems}
              actions={actions}
              suvc={suvc}
              graphFilter={graphFilter}
              filterForGraphs={scorecards.filterForGraphs}
              taxonomy={scorecards.taxonomy}
              brands={scorecards.brands}
              tableFilters={tableFilters}
              orderBy={orderBy}
              brandGroupFilters={brandGroupFilters}
              tableSearchData={tableSearchData}
              isComplexRendering={isFiltersComplex}
              clearAllFilters={this.clearAllFilters}
              isHiddingFilters
            />
          );
        case 'fnb':
          return (
            <FnbCharts
              summaryItems={summaryItems}
              actions={actions}
              suvc={suvc}
              graphFilter={graphFilter}
              filterForGraphs={scorecards.filterForGraphs}
              taxonomy={scorecards.taxonomy}
              brands={scorecards.brands}
              tableFilters={tableFilters}
              orderBy={orderBy}
              brandGroupFilters={brandGroupFilters}
              tableSearchData={tableSearchData}
              isComplexRendering={isFiltersComplex}
              clearAllFilters={this.clearAllFilters}
              isHiddingFilters
            />
          );
        case 'image':
          return (
            <ImageCharts
              summaryItems={summaryItems}
              actions={actions}
              suvc={suvc}
              graphFilter={graphFilter}
              filterForGraphs={scorecards.filterForGraphs}
              taxonomy={scorecards.taxonomy}
              brands={scorecards.brands}
              tableFilters={tableFilters}
              orderBy={orderBy}
              brandGroupFilters={brandGroupFilters}
              tableSearchData={tableSearchData}
              isComplexRendering={isFiltersComplex}
              clearAllFilters={this.clearAllFilters}
              isHiddingFilters
            />
          );
        case 'regulatoryCompliance':
          return (
            <RegulatoryComplianceCharts
              summaryItems={summaryItems}
              actions={actions}
              suvc={suvc}
              graphFilter={graphFilter}
              filterForGraphs={scorecards.filterForGraphs}
              taxonomy={scorecards.taxonomy}
              brands={scorecards.brands}
              tableFilters={tableFilters}
              orderBy={orderBy}
              brandGroupFilters={brandGroupFilters}
              tableSearchData={tableSearchData}
              isComplexRendering={isFiltersComplex}
              clearAllFilters={this.clearAllFilters}
              isHiddingFilters
            />
          );
        default:
          return null;
      }
    };

    return (
      <React.Fragment>
        <AttributeMassEditModal suvc={this.props.suvc} />
        <MassImageUpdate supcs={this.state.selectedSupcs} />
        <FnBMassUpdateModal
          ref={this.fnbMassEditRef}
          suvc={suvc}
          supcs={this.state.selectedSupcs}
          rejectedFnbs={rejectedFnbs}
        />
        <PfasMassEditModal suvc={this.props.suvc} />
        <FsmaMassEditModal selectedItems={this.selectedRows} />
        <div
          className="grid-filter-panel"
          style={getValueBasedOnTheCondition(
            areNotificationBannersAvailable || scorecards.scoresLoading,
            {},
            { position: 'absolute' }
          )}
        >
          {!this.props.isInternal && (
            <React.Fragment>
              <div
                className="back-to-dashboard"
                onClick={() => {
                  window.location.href = '/suite/dashboard';
                }}
              ></div>
              <div className="bread-crum-seperator"></div>
              <div className="bread-crum-caption">Item Management</div>
            </React.Fragment>
          )}
          <div className="search-bar">
            <RejectCountBanner
              count={feeExCount}
              className="fee-exclusions-banner"
              isFilterOn={feeFilterOn}
              onToggle={this.toggleFeeFilterBanner}
              title={'Enhancement Opportunities count'}
              countMessage={`Enhancement Opportunit${getValueBasedOnTheCondition(feeExCount > 1, 'ies', 'y')}!`}
            />
            {this.props.autoSavedFnb && this.props.autoSavedFnb.length > 0 && (
              <div
                className={`reject-filter-banner unsaved${getValueBasedOnTheCondition(
                  isAutoSavedFnbFilterOn,
                  ' active',
                  ''
                )}`}
                onClick={this.toggleAutoSavedFnbBanner}
                title="Unsaved FnB Count"
              >
                <div className="fnbr-count auto-save">{this.props.autoSavedFnb.length}</div>
                <div>Unsaved FnB{getValueBasedOnTheCondition(this.props.autoSavedFnb.length > 1, 's', '')}!</div>
              </div>
            )}
            <RejectCountBanner
              count={imageRejectCount}
              className="reject-filter-banner"
              isFilterOn={imageRejectFilterOn}
              onToggle={this.toggleRejectedImageFilterBanner}
              title={'Rejected Image Count'}
              countMessage={`Image Reject${getValueBasedOnTheCondition(imageRejectCount > 1, 's', '')}!`}
            />
            <RejectCountBanner
              count={fnbRejectCount}
              className="reject-filter-banner"
              isFilterOn={fnbRejectFilterOn}
              onToggle={this.toggleRejectedFnBFilterBanner}
              title={'Rejected FnB Count'}
              countMessage={`FnB Reject${getValueBasedOnTheCondition(fnbRejectCount > 1, 's', '')}!`}
            />
            <RejectCountBanner // comment this lines when disabling enut application
              count={nutritionRejectCount}
              className="reject-filter-banner"
              isFilterOn={nutritionRejectFilterOn}
              onToggle={this.toggleRejectedNutritionFilterBanner}
              title="Rejected Nutrition Count"
              countMessage={`Nutrition Reject${getValueBasedOnTheCondition(nutritionRejectCount > 1, 's', '')}!`}
            />
            <RejectCountBanner
              count={coreDataRejectCount}
              className="reject-filter-banner"
              isFilterOn={coreDataRejectFilterOn}
              onToggle={this.toggleRejectedCoreDataFilterBanner}
              title={'Rejected Core Data Count'}
              countMessage={`Core Data Reject${getValueBasedOnTheCondition(coreDataRejectCount > 1, 's', '')}!`}
            />
          </div>
        </div>
        <div
          className="score-cards"
          style={{ display: getValueBasedOnTheCondition(scorecards.scoresLoading, 'none', 'block') }}
        >
          <div className="cards">
            {filteredScoreCards.map(scorecard => (
              <ScoreCard
                key={scorecard.title}
                actions={actions}
                cardType={scorecard.cardType}
                title={scorecard.title}
                score={scorecard.score}
                loading={scorecards.scoresLoading}
                activeChart={scorecards.activeChart}
                summaryItems={summaryItems}
                suvc={suvc}
                graphFilter={graphFilter}
                filterForGraphs={scorecards.filterForGraphs}
                overallSummary={overallSummary}
                explain={getValueBasedOnTheCondition(
                  scorecard.explain,
                  scorecard.explain,
                  scorecard.title.toLowerCase()
                )}
                tableFilters={tableFilters}
                orderBy={orderBy}
                brandGroupFilters={brandGroupFilters}
                tableSearchData={tableSearchData}
              />
            ))}
          </div>
          <div className="charts">{handleRenderingScoreCards()}</div>
        </div>
        {/* {this.props.autoSavedFnb && this.props.autoSavedFnb.length > 0 && (
          <div className="auto-saved">
            <span>You have {this.props.autoSavedFnb.length} unsaved data</span>
          </div>
        )} */}
        <ReactResizeDetector
          handleWidth
          handleHeight
          render={({ width, height }) => (
            <div className="grid-panel items-list-table items-list-table-wrapper">
              {this.renderBrandFilters()}
              <Table
                rowKey={'supc'}
                className="items-list-tbl"
                columns={columns}
                dataSource={list}
                size="small"
                pagination={false}
                onRowClick={onRowHandler}
                onChange={this.handleFilterChanges}
                rowClassName={item => {
                  return getValueBasedOnTheCondition(item.supc === selectedSupc, 'selected-row', '');
                }}
                rowSelection={getValueBasedOnTheCondition(this.props.isInternal, null, rowSelection)}
              />
              <Pagination
                size="small"
                className="grid-pagination"
                onChange={handlePagination}
                total={recordCount}
                showTotal={total => `Total ${total} items`}
                current={page}
                defaultPageSize={50}
                hideOnSinglePage
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

export default ItemList;
