import React from 'react';
import { Checkbox, Select } from 'antd';
import _ from 'lodash';
import RegulatoryComplianceOverallDonutChart from './RegulatoryComplianceOverallDonutChart';
import RegulatoryComplianceBarChart from './RegulatoryComplianceBarChart';
import RegulatoryComplianceBarChartByCriteria from './RegulatoryComplianceBarChartByCriteria';

import {
  filterSummaryItems,
  parseJson,
  appendToArrayWithSpread,
  getValueBasedOnTheCondition,
  sortArray,
  stringifyJson,
  getFilterGraphParameters,
  getConditionStatus
} from '../util/Util';

class RegulatoryComplianceCharts extends React.Component {
  render() {
    let {
      summaryItems,
      actions,
      suvc,
      graphFilter,
      filterForGraphs,
      taxonomy,
      brands,
      tableFilters,
      orderBy,
      brandGroupFilters,
      tableSearchData,
      isComplexRendering,
      clearAllFilters,
      isHiddingFilters
    } = this.props;

    const { Option } = Select;

    const paramTypes = ['rcfsma'];

    const stripFromFilter = strip => {
      const existingGraphFilter = JSON.parse(graphFilter);
      const strippedFilters = _.filter(existingGraphFilter, filterObj => {
        return strip.indexOf(filterObj.param) === -1;
      });
      return strippedFilters;
    };

    const filterGraph = (add, remove) => {
      let filterStr = null;

      let currentGraphFilter = parseJson(graphFilter);

      currentGraphFilter = stripFromFilter(remove);

      currentGraphFilter = appendToArrayWithSpread(currentGraphFilter, add);

      filterStr = stringifyJson(currentGraphFilter);

      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: filterStr,
        tableFilters,
        orderBy,
        brandGroupFilters,
        tableSearchData
      });
    };

    const filteredItems = filterSummaryItems(summaryItems, filterForGraphs, graphFilter, tableFilters);
    let businessCenters = Object.getOwnPropertyNames(taxonomy);
    let itemGroups = [];
    let attributeGroups = [];

    if (filterForGraphs.bc !== null) {
      itemGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc]);
      if (filterForGraphs.ig !== null) {
        attributeGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc][filterForGraphs.ig]);
      }
    }

    if (summaryItems && summaryItems.length > 0) {
      let graphsPanel = null;
      if (filteredItems.length === 0) {
        graphsPanel = <div className="loading-msg">No data to visualize for the selected filter.</div>;
      } else {
        graphsPanel = (
          <div className="attribution-charts-wrapper-graphs" style={{ justifyContent: 'center' }}>
            <RegulatoryComplianceOverallDonutChart
              summaryItems={filteredItems}
              filterGraph={filterGraph}
              paramTypes={paramTypes}
            />
            <RegulatoryComplianceBarChartByCriteria
              summaryItems={filteredItems}
              filterGraph={filterGraph}
              paramTypes={paramTypes}
            />
            <RegulatoryComplianceBarChart
              summaryItems={filteredItems}
              filterGraph={filterGraph}
              paramTypes={paramTypes}
            />
          </div>
        );
      }

      brands = sortArray(brands);
      businessCenters = sortArray(businessCenters);
      itemGroups = sortArray(itemGroups);
      attributeGroups = sortArray(attributeGroups);

      return (
        <div className="attribution-charts-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`filter-fanel${graphFilter ? ' active' : ''}`}
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            style={{ enableBackground: 'new -100 -100 700 700' }}
            fill="#abac92"
            viewBox="-100 -120 700 700"
            onClick={clearAllFilters}
          >
            <g>
              <path d="M241.7,512H192V253.7L12.8,40.3V0h486.4v40.1L328.5,253.5v171.8L241.7,512z M226.1,477.9h1.5l66.8-66.8V241.5L460.3,34.1   H52.2l174,207.1L226.1,477.9z" />
            </g>
          </svg>
          {graphsPanel}

          {isComplexRendering && (
            <div className="filter-complex-container">
              <span className="warning-text">* Filters are too complex to render graphs</span>
              <span onClick={clearAllFilters} className="reset-span">
                Reset filters
              </span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="attribution-charts-wrapper">
        <div className="loading-msg">Loading....</div>
      </div>
    );
  }
}

export default RegulatoryComplianceCharts;
