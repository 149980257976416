/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
import { put, takeLatest, delay } from 'redux-saga/effects';
import { getRequest, postRequest, patchRequest, imagesUploadRequest } from '_http';
import * as _ from 'lodash';

import {
  RETRIEVEMASSIMAGEUPLOADDATA,
  CHANGEMASSIMAGELOADINGPERCENTAGE,
  MASSIMAGEUPLOADITEMDATALOADEDDONE,
  MASSIMAGEUPLOADSUBMIT,
  UPDATEMASSIMAGEUPLOADFIELDS,
  MASSIMAGEUPLOADCOMMENTSRESPONSE,
  COMPLETEMASSIMAGEUPLOADJOB,
  VALIDATEMASSUPLOADIMAGES,
  VALIDATECOMPLETEDMASSUPLOADIMAGES,
  CHECKTIMEOUTFORIMAGES,
  ALLBUSINESSCENTERS,
  ATTRIBUTEMASSEDITLIST,
  ATTRIBUTESLIST,
  MASSUPDATEATTRIBUTES,
  CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE,
  MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT,
  ALLBUSINESSCENTERSPFAS,
  PFASMASSEDITLIST,
  SUBMITMASSPFASCHANGES,
  SAVEFSMACHANGESLIST,
  MASSSAVEFSMACHANGES
} from 'actions/actionTypes';
import { action } from 'reduxHelpers';
import {
  TAXONOMY_AVERAGE_LATENCY,
  COMMENTS_AVERAGE_LATENCY,
  IMAGES_AVERAGE_LATENCY,
  MASS_IMAGE_UPLOAD_FIRST_STEP_PROGRESS,
  MASS_IMAGE_UPLOAD_SECOND_STEP_PROGRESS,
  MASS_IMAGE_UPLOAD_THIRD_STEP_PROGRESS,
  MASS_IMAGE_UPLOAD_FOURTH_STEP_PROGRESS,
  MASS_IMAGE_UPLOAD_LAST_STEP_PROGRESS,
  MASS_IMAGE_UPLOAD_INITIAL_STEP_PROGRESS
} from '../util/Constants';
import {
  calculateAvaregeLatencyForImageUpload,
  mergeAttributesWithItems,
  getNutritionsAndPending,
  pendingNutritionCommentParams,
  devideTaxonomyAndSynchedTaxonomyChanges,
  getPendingOsdNutritionChanges,
  getExistingUpdatedFieldsEnut,
  getUpdatedOrganicStatusChangedFields,
  mergePendingAndOsdTaxonomyChanges,
  getFormattedItemTaxonomyForMassAttribution,
  getMergedChangedOsdAttributes,
  getMergedChangedTaxonomy,
  setUpdatedDataForNutritionalFeedback,
  getPendingNutritionsForMassAttribution,
  getNutritionalFeedbackStatus,
  mergePfasWithItems,
  updateSingleItemDetailsFromTheList
} from '../util/Util';
import osdNutrition from '../util/osdNutrition';

const getTotalSpentTime = (commentsSuccessCount, taxonomySuccessCount, imagesSuccessCount) => {
  return (
    TAXONOMY_AVERAGE_LATENCY * taxonomySuccessCount +
    COMMENTS_AVERAGE_LATENCY * commentsSuccessCount +
    IMAGES_AVERAGE_LATENCY * imagesSuccessCount
  );
};

const getTotlaEstimatedTime = itemCount =>
  itemCount * (TAXONOMY_AVERAGE_LATENCY + COMMENTS_AVERAGE_LATENCY + IMAGES_AVERAGE_LATENCY);

const calculateLoadingPercentage = (itemCount, commentsCount, taxonomyCount, imageCount) => {
  const totalEstimatedTime = getTotlaEstimatedTime(itemCount);
  const timeSpent = getTotalSpentTime(commentsCount, taxonomyCount, imageCount);
  try {
    return Math.round((timeSpent / totalEstimatedTime) * 100);
  } catch (error) {
    return 0;
  }
};

const getDefaultDataTypes = type => {
  switch (type) {
    case 'comments':
      return [];
    case 'taxonomy':
      return {};
    case 'images':
      return {};
    default:
      return null;
  }
};

function* getCommentsEachTypeDataForMassImages({ supc, suvc, type }) {
  let responseData = null;
  try {
    const { data } = yield getRequest(`/suvc/${suvc}/items/${supc}/${type}`);
    responseData = data;
  } catch (error) {
    return getDefaultDataTypes(type);
  }
  return responseData;
}

function* setLoadingPercentage({
  supcsLength,
  commentsSuccessCount,
  taxonomySuccessCount,
  imagesSuccessCount,
  ...rest
}) {
  const percentage = calculateLoadingPercentage(
    supcsLength,
    commentsSuccessCount,
    taxonomySuccessCount,
    imagesSuccessCount
  );
  yield put({ type: CHANGEMASSIMAGELOADINGPERCENTAGE.REQUEST, payload: { percentage, ...rest } });
}

const fetchBasicSupcData = (supc, itemList) => {
  return _.find(itemList, { supc });
};

const filterPendingImageComments = allComments => {
  return allComments.filter(({ field, status }) => field === 'IMAGE' && [0, 10].includes(status));
};

const getImageUploadStatusForEachType = ({
  selectedImagePreview,
  imageAngle,
  imageFacing,
  imageStyle,
  imageType,
  formattedImageName,
  selectedImage,
  isValidated
}) =>
  selectedImagePreview &&
  imageAngle &&
  imageFacing &&
  imageStyle &&
  imageType &&
  formattedImageName &&
  selectedImage &&
  isValidated;

function* getMassImageUploadDataAsync({ supcs, suvc, itemList }) {
  try {
    if (supcs && supcs.length) {
      let iterator = 0;
      const supcsLength = supcs.length;
      while (iterator < supcs.length) {
        try {
          const basicData = fetchBasicSupcData(supcs[iterator], itemList);
          const increasedIterator = iterator + 1;
          const supc = supcs[iterator];
          // fetch comments for supc
          const commentsResponse = yield getCommentsEachTypeDataForMassImages({ supc, suvc, type: 'comments' });
          const pendingImageComments = filterPendingImageComments(commentsResponse);
          yield setLoadingPercentage({
            supcsLength,
            commentsSuccessCount: increasedIterator,
            taxonomySuccessCount: iterator,
            imagesSuccessCount: iterator,
            comments: pendingImageComments,
            supc
          });

          // fetch taxonomy for supc
          const taxonomyResponse = yield getCommentsEachTypeDataForMassImages({ supc, suvc, type: 'taxonomy' });
          yield setLoadingPercentage({
            supcsLength,
            commentsSuccessCount: increasedIterator,
            taxonomySuccessCount: increasedIterator,
            imagesSuccessCount: iterator,
            taxonomy: taxonomyResponse,
            supc
          });

          // fetch images for supc
          const imageResponse = yield getCommentsEachTypeDataForMassImages({ supc, suvc, type: 'images' });
          yield setLoadingPercentage({
            supcsLength,
            commentsSuccessCount: increasedIterator,
            taxonomySuccessCount: increasedIterator,
            imagesSuccessCount: increasedIterator,
            images: imageResponse,
            supc,
            basicData
          });
        } catch (error) {
        } finally {
          yield iterator++;
        }
      }
      yield put({ type: MASSIMAGEUPLOADITEMDATALOADEDDONE.REQUEST });
    }
  } catch (error) {
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD YOUR DATA"
      })
    );
  }
}

function* handleEachImageFieldPercentage(data) {
  yield put({ type: UPDATEMASSIMAGEUPLOADFIELDS.REQUEST, ...data });
}

function* handleEachImageUploadAndValidationAsync(
  { reservationResponse, supc, originFileObj, imageField, size },
  validationOnly = false
) {
  const bodyFormData = new FormData();
  bodyFormData.append('new_image', originFileObj);
  if (reservationResponse) {
    bodyFormData.append('reservation_id', reservationResponse.data.id);
  }
  bodyFormData.append('supc', supc);
  if (size) {
    yield put({
      type: CHECKTIMEOUTFORIMAGES.REQUEST,
      supc,
      fieldType: imageField,
      fieldUploadPercentage: MASS_IMAGE_UPLOAD_THIRD_STEP_PROGRESS,
      size
    });
  }
  const { data, status } = yield imagesUploadRequest(
    `/otmmstream/upload?validations=${validationOnly ? 'only' : 'all'}`,
    bodyFormData
  );
  let errors = [];
  let isValidated = true;
  if (status === 400 && data.status === 'ERROR') {
    const { errors: allValidationErrors } = data;
    errors = allValidationErrors;
    isValidated = false;
    yield put({ type: CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE.REQUEST, supc, imageField });
  }
  yield put({ type: UPDATEMASSIMAGEUPLOADFIELDS.REQUEST, supc, fieldType: imageField, errors, isValidated });
  return { data, status };
}

function* handleEachImageAsync({ suvc, supc, eachChange, stepId, imageField }) {
  const { imageStyle, formattedImageName, selectedImage, isValidated } = eachChange;
  if (isValidated) {
    const {
      value: { name, type, size, originFileObj }
    } = selectedImage;
    const postData = {
      name,
      contentType: type,
      extension: /[^.]+$/.exec(name)[0],
      metadata: {},
      size,
      imageStyle
    };
    try {
      yield handleEachImageFieldPercentage({
        supc,
        fieldType: imageField,
        fieldUploadPercentage: MASS_IMAGE_UPLOAD_FIRST_STEP_PROGRESS,
        isUploading: true
      });
      const reservationResponse = yield postRequest(`/suvc/${suvc}/items/${supc}/images`, postData, true);
      if (reservationResponse && reservationResponse.data && reservationResponse.data.id) {
        yield handleEachImageFieldPercentage({
          supc,
          fieldType: imageField,
          fieldUploadPercentage: MASS_IMAGE_UPLOAD_SECOND_STEP_PROGRESS
        });
        const { data, status } = yield handleEachImageUploadAndValidationAsync({
          reservationResponse,
          supc,
          originFileObj,
          imageField,
          size
        });
        if (status === 200) {
          const commentData = {
            supc,
            suvc,
            comment: `New ${imageStyle} image for ${supc}`,
            style: imageStyle,
            meta: JSON.stringify({
              ...data.data,
              supc,
              style: imageStyle,
              name: formattedImageName
            }),
            reservationId: reservationResponse.data.id,
            stepId
          };
          yield handleEachImageFieldPercentage({
            supc,
            fieldType: imageField,
            fieldUploadPercentage: MASS_IMAGE_UPLOAD_FOURTH_STEP_PROGRESS
          });
          yield postRequest(`/suvc/${suvc}/items/${supc}/images/create`, commentData);
          yield handleEachImageFieldPercentage({
            supc,
            fieldType: imageField,
            fieldUploadPercentage: MASS_IMAGE_UPLOAD_LAST_STEP_PROGRESS
          });
        }
      }
    } catch (error) {}
  }
  yield handleEachImageFieldPercentage({
    supc,
    fieldType: imageField,
    fieldUploadPercentage: MASS_IMAGE_UPLOAD_INITIAL_STEP_PROGRESS,
    isUploading: false
  });
}

function* uploadMassImagesAsync({ massImages, suvc }) {
  if (massImages) {
    const imageUploadSupcs = Object.keys(massImages);
    let iterator = 0;
    const supcsLength = imageUploadSupcs.length;
    while (iterator < supcsLength) {
      const supc = imageUploadSupcs[iterator];
      if (massImages[supc]) {
        const { changes, basicData } = massImages[supc];
        if (changes) {
          const imageTypes = Object.keys(changes);
          let typesIterator = 0;
          const typesLength = imageTypes.length;
          while (typesIterator < typesLength) {
            if (getImageUploadStatusForEachType(changes[imageTypes[typesIterator]])) {
              yield handleEachImageAsync({
                suvc,
                supc,
                eachChange: changes[imageTypes[typesIterator]],
                stepId: basicData.stepId,
                imageField: imageTypes[typesIterator]
              });
            }
            typesIterator++;
          }
        }
      }
      const commentsResponse = yield getCommentsEachTypeDataForMassImages({ supc, suvc, type: 'comments' });
      const pendingImageComments = filterPendingImageComments(commentsResponse);
      yield put({
        type: MASSIMAGEUPLOADCOMMENTSRESPONSE.REQUEST,
        supc,
        comments: pendingImageComments
      });
      iterator++;
    }
  }
  yield put({ type: COMPLETEMASSIMAGEUPLOADJOB.REQUEST });
  yield put(
    action('SHOW_NOTIFICATION', {
      description: 'IMAGES SENT',
      className: 'info',
      message: 'SUCCESS'
    })
  );
}

function* validateMassImagesAsync({ changes, supc, suvc }) {
  if (changes) {
    const itemProperties = Object.keys(changes);
    let iterator = 0;
    while (iterator < itemProperties.length) {
      const eachProperty = changes[itemProperties[iterator]];
      if (Object.keys(eachProperty).length) {
        const { errors, selectedImage, isValidated } = changes[itemProperties[iterator]];
        if (!errors || (errors && errors.length) || !isValidated) {
          const {
            value: { originFileObj }
          } = selectedImage;
          const imageField = itemProperties[iterator];
          const { data } = yield handleEachImageUploadAndValidationAsync(
            {
              reservationResponse: { data: { id: Math.random() } },
              supc,
              originFileObj,
              imageField
            },
            true
          );
          const { status: errorStatus } = data;
          if (errorStatus === 'OK') {
            yield put({
              type: UPDATEMASSIMAGEUPLOADFIELDS.REQUEST,
              supc,
              fieldType: imageField,
              isValidated: true
            });
          }
        }
      }
      iterator++;
    }
    yield put({ type: VALIDATECOMPLETEDMASSUPLOADIMAGES.REQUEST, supc, isValidating: false });
  }
  yield put(
    action('SHOW_NOTIFICATION', {
      description: 'PLEASE FIND THE VALIDATION NOTES',
      className: 'info',
      message: 'VALIDATED'
    })
  );
}

function* checkTimeOutAsync(data) {
  const { type, size, ...rest } = data;
  yield delay(calculateAvaregeLatencyForImageUpload(size));
  yield put({ type: UPDATEMASSIMAGEUPLOADFIELDS.REQUEST, ...rest });
}

function* getAllBusinessCentersAsync({ bcIds, actionType }) {
  const { FAILURE, SUCCESS } = actionType;
  try {
    const response = yield getRequest(`/taxonomy/upper?bcs=${bcIds.join(',')}`);

    yield put({ type: SUCCESS, payload: _.get(response, 'data', []) });
  } catch (error) {
    yield put({ type: FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT GET TAXONOMY'
      })
    );
  }
}

const getFilteringUriForMassAttribution = ({ tableFilters, tableSearchData, page, orderBy, graphFilter }) => {
  const filters = [...tableFilters, ...tableSearchData];
  let uri = `?page=${page}`;

  if (orderBy) {
    uri = `${uri}&orderby=${encodeURIComponent(JSON.stringify(orderBy))}`;
  }

  if (graphFilter) {
    uri = `${uri}&graphfilter=${encodeURIComponent(JSON.stringify(graphFilter))}`;
  }

  if (!_.isEmpty(filters)) {
    uri = `${uri}&tablefilter=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  return `${uri}&pagesize=15`;
};

async function getPfasItemListRelatedValues(items) {
  const success = [];
  const failed = [];
  const promiseArray = await _.map(items, ({ supc, suvc }) => {
    const pfasValues = getRequest(`/items/${supc}/pfas`);
    // const attributes = getRequest(`/suvc/${suvc}/items/${supc}/attributes`);
    const taxonomy = getRequest(`/suvc/${suvc}/items/${supc}/taxonomy`);
    return [pfasValues, taxonomy];
  });

  const results = await Promise.allSettled(promiseArray);
  let index = 0;

  for (const res of results) {
    // eslint-disable-next-line no-await-in-loop
    const promiseResults = await Promise.allSettled(res.value);
    const supc = _.get(items[index], 'supc', null);
    const [
      { status: pfasValuesPromiseStatus, value: pfasValuesPromiseValue },
      { status: taxonomyPromiseStatus, value: taxonomyPromiseValue }
    ] = promiseResults;

    if (pfasValuesPromiseStatus === 'fulfilled' && taxonomyPromiseStatus === 'fulfilled') {
      success.push({
        ...pfasValuesPromiseValue.data,
        taxonomyAttributes: [...taxonomyPromiseValue.data.attributes]
      });
    } else {
      failed.push(supc);
    }
    index++;
  }
  return { success, failed };
}

function* getPfasMassEditListAsync({
  suvc,
  page = 1,
  orderBy = null,
  graphFilter = null,
  tableFilters = [],
  tableSearchData = [],
  isEmptyHierarchy = false
}) {
  try {
    const uri = getFilteringUriForMassAttribution({
      tableFilters,
      tableSearchData,
      page,
      orderBy,
      graphFilter
    }).replace('pagesize=15', 'pagesize=10');

    let response = {};
    let list = [];

    if (!isEmptyHierarchy) {
      response = yield getRequest(`/suvc/${suvc}/items${uri}`);
      list = _.get(response, 'data.items', []);

      const { success, failed } = yield getPfasItemListRelatedValues(list);

      list = mergePfasWithItems(list, success);

      if (!_.isEmpty(failed)) {
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'Could not fetch pfas/attributes of some items',
            className: 'error',
            message: 'COULD NOT FETCH ATTRIBUTES'
          })
        );
      }
    }
    yield put({
      type: PFASMASSEDITLIST.SUCCESS,
      payload: {
        list,
        page,
        recordsCount: _.get(response, 'data.count', 0),
        orderBy,
        tableFilters,
        tableSearchData
      }
    });
  } catch (error) {
    yield put({ type: PFASMASSEDITLIST.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT FETCH LIST'
      })
    );
  }
}

function* getAttributeMassEditListAsync({
  suvc,
  page = 1,
  orderBy = null,
  graphFilter = null,
  tableFilters = [],
  tableSearchData = [],
  isEmptyHierarchy = false
}) {
  try {
    const uri = getFilteringUriForMassAttribution({ tableFilters, tableSearchData, page, orderBy, graphFilter });

    let response = {};
    let list = [];

    if (!isEmptyHierarchy) {
      response = yield getRequest(`/suvc/${suvc}/items${uri}`);
      list = _.get(response, 'data.items', []);

      const { success, failed } = yield getAttributeValues(suvc, list);

      list = mergeAttributesWithItems(list, success);

      if (!_.isEmpty(failed)) {
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'Could not fetch attributes of some items',
            className: 'error',
            message: 'COULD NOT FETCH ATTRIBUTES'
          })
        );
      }
    }

    yield put({
      type: ATTRIBUTEMASSEDITLIST.SUCCESS,
      payload: {
        list,
        page,
        recordsCount: _.get(response, 'data.count', 0),
        orderBy,
        tableFilters,
        tableSearchData
      }
    });
  } catch (error) {
    yield put({ type: ATTRIBUTEMASSEDITLIST.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT FETCH LIST'
      })
    );
  }
}

function* getAttributesAndPicklistValuesAsync({ bc, ig, ag, isPartial }) {
  const ids = `${bc}-${ig}-${ag}`;

  try {
    const response = yield getRequest(`/taxonomy/lower/${ids}`);
    yield put({
      type: ATTRIBUTESLIST.SUCCESS,
      payload: {
        isPartial,
        attributes: _.get(response, 'data', [])
      }
    });
  } catch (error) {
    yield put({ type: ATTRIBUTESLIST.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT FETCH ATTRIBUTES'
      })
    );
  }
}

function* massUpdateAttributesAsync({ suvc, updatedData, list: itemList, attributes }) {
  try {
    const { success, failed, updatedNutritionalResults } = yield saveMultipleAttributes(
      suvc,
      updatedData,
      itemList,
      attributes
    );

    yield put({
      type: MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT.SUCCESS,
      payload: updatedNutritionalResults
    });

    if (!_.isEmpty(failed)) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Couldn't update attributes of the following items: ${_.join(failed, ', ')}`,
          className: 'error',
          message: 'UPDATING ATTRIBUTES FAILED'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Successfully updated attributes!',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }

    yield put({ type: MASSUPDATEATTRIBUTES.SUCCESS, payload: { failed, success } });
  } catch (error) {
    yield put({ type: MASSUPDATEATTRIBUTES.FAILURE });
  }
}

const handleNutritionalFeedbackWithMassAttribution = ({
  pendingNutrition,
  formattedChangedAttributes,
  attributes,
  nutritions,
  trueVendorName,
  gln,
  suvc,
  hasNutritionSynchedTaxonomyAttributeChanges,
  gtin,
  materialDescription,
  supc,
  allAttributes,
  stepId,
  pendingNutritions
}) => {
  const mergedChangedOsdAttributes = getMergedChangedOsdAttributes(pendingNutrition, formattedChangedAttributes);

  const mergedChangedTaxonomy = getMergedChangedTaxonomy(attributes, mergedChangedOsdAttributes);

  const { traits, nutritionalClaimsDetails, organicTradeItemCodes } = osdNutrition.changeTaxonomyToNutrition({
    nutritions,
    taxonomy: { attributes: mergedChangedTaxonomy }
  });

  let updatedChangedFields = getExistingUpdatedFieldsEnut(pendingNutrition);

  updatedChangedFields = [
    ...getUpdatedOrganicStatusChangedFields(organicTradeItemCodes, nutritions, updatedChangedFields)
  ];

  let updatedData = setUpdatedDataForNutritionalFeedback({
    pendingNutrition,
    traits,
    nutritionalClaimsDetails,
    organicTradeItemCodes,
    trueVendorName,
    gln,
    suvc,
    updatedChangedFields
  });

  const { sleHasProductFormulationStmt } = pendingNutrition;
  if (sleHasProductFormulationStmt && !nutritions.sleHasProductFormulationStmt) {
    updatedData = {
      ...updatedData,
      sleProductId: null,
      changedFields: [...new Set([...updatedData.changedFields, 'sleProductId'])]
    };
  }
  if (!pendingNutrition.oid) {
    updatedData = {
      ...updatedData,
      gtin,
      gln,
      alternateIdentifier: supc,
      name: materialDescription
    };
  }

  // skipping CN for mass update
  const [taxonomyChangesOnly, nutritionSynchedTaxonomyChangesOnly] = devideTaxonomyAndSynchedTaxonomyChanges(
    mergedChangedOsdAttributes,
    attributes,
    true
  );

  updatedData = { ...updatedData, taxonomyChanges: nutritionSynchedTaxonomyChangesOnly };

  const comment = {
    id: null,
    suvc,
    stepId,
    field: 'NUTRITION',
    type: 'NUTRITION',
    comment: JSON.stringify({ ...updatedData }),
    originalValue: ''
  };

  const nutritionCommentPostData = {
    comments: [comment],
    stepId,
    supc,
    suvc
  };

  const changePromisses = [];

  if (taxonomyChangesOnly && taxonomyChangesOnly.length) {
    changePromisses.push(
      patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, {
        comment: '',
        attributes: taxonomyChangesOnly
      })
    );
  }
  if (pendingNutritions && pendingNutritions.length) {
    const { id, status } = pendingNutritions[0];
    const { newValue, statusText } = getNutritionalFeedbackStatus(status);
    changePromisses.push(
      patchRequest(`/dash/comments/${id}/status/${newValue}`, {
        commentId: id,
        comment: JSON.stringify({ ...updatedData, feedback: { ...updatedData.feedback, status: statusText } }),
        status: statusText
      })
    );
  } else {
    changePromisses.push(postRequest(`/suvc/${suvc}/items/${supc}/comments`, nutritionCommentPostData));
  }
  return changePromisses;
};

const getSelectedItem = (itemList, itemSupc) => itemList.filter(({ supc }) => itemSupc === supc);

const checkArrayFullfilledStatus = ({ status }) => status === 'fulfilled';

const createPromissesForMassAttributes = (suvc, data, itemList, allAttributes) => {
  return _.map(data, obj => {
    const formattedChangedAttributes = _.map(obj.attributes, attr => {
      return { attrId: attr.id, selected: attr.value };
    });

    const postData = {
      comment: '', // TODO: taxonomyComment
      attributes: formattedChangedAttributes
    };

    const [
      {
        nutritions,
        pendingNutrition,
        pendingNutritions,
        gln,
        hasNutritionSynchedTaxonomyAttributeChanges,
        trueVendorName,
        gtin,
        materialDescription,
        stepId,
        taxonomy
      }
    ] = getSelectedItem(itemList, obj.supc);
    if (hasNutritionSynchedTaxonomyAttributeChanges) {
      return handleNutritionalFeedbackWithMassAttribution({
        pendingNutrition,
        pendingNutritions,
        allAttributes,
        stepId,
        supc: obj.supc,
        suvc,
        trueVendorName,
        gtin,
        gln,
        attributes: taxonomy.attributes,
        hasNutritionSynchedTaxonomyAttributeChanges,
        nutritions,
        formattedChangedAttributes,
        materialDescription
      });
    }
    return patchRequest(`/suvc/${suvc}/items/${obj.supc}/taxonomy`, postData);
  });
};

async function saveMultipleAttributes(suvc, data, itemList, allAttributes) {
  const success = [];
  const failed = [];
  const updatedNutritionalResults = [];

  // get all the promisses related saving mass attribution with nutritional feedback
  const promiseArray = createPromissesForMassAttributes(suvc, data, itemList, allAttributes);

  const results = await Promise.allSettled(promiseArray);
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const res of results) {
    const supc = _.get(data[index], 'supc', null);
    const { status, value } = res;
    const isPromissArray = Array.isArray(value);
    let isAllPromissesResolved = false;
    if (isPromissArray) {
      // eslint-disable-next-line no-await-in-loop
      const taxonomyAndNutritionsResults = await Promise.allSettled(value);
      isAllPromissesResolved = taxonomyAndNutritionsResults.every(checkArrayFullfilledStatus);
      const [{ pendingNutrition }] = getSelectedItem(itemList, supc);
      // eslint-disable-next-line no-await-in-loop
      const newComments = await getRequest(`/dash/comments${pendingNutritionCommentParams(supc)}`);
      const { comments } = getNutritionsAndPending(newComments, 'COMMENT');
      const existingPendingNutrition = getPendingNutritionsForMassAttribution(comments, pendingNutrition);
      updatedNutritionalResults.push({
        supc,
        pendingNutrition: existingPendingNutrition,
        pendingNutritions: comments
      });
    }
    if ((status === 'fulfilled' && !isPromissArray) || (isPromissArray && isAllPromissesResolved)) {
      success.push(supc);
    } else {
      failed.push(supc);
    }
    index++;
  }

  return { success, failed, updatedNutritionalResults };
}

async function getAttributeValues(suvc, items) {
  const success = [];
  const failed = [];
  const promiseArray = await _.map(items, ({ supc }) => {
    const pendingComments = getRequest(`/dash/comments${pendingNutritionCommentParams(supc)}`);

    const attributes = getRequest(`/suvc/${suvc}/items/${supc}/attributes`);
    const taxonomy = getRequest(`/suvc/${suvc}/items/${supc}/taxonomy`);
    const nutritions = getRequest(`/nutritions/${supc}`);
    return [attributes, nutritions, pendingComments, taxonomy];
  });

  const results = await Promise.allSettled(promiseArray);
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const res of results) {
    // eslint-disable-next-line no-await-in-loop
    const promiseResults = await Promise.allSettled(res.value);
    const supc = _.get(items[index], 'supc', null);
    const [
      { status: attributePromiseStatus, value: attributePromiseValue },
      { status: nutritionPromiseStatus, value: nutritionPromiseValue },
      { status: pendingCommentPromiseStatus, value: pendingCommentPromiseValue },
      { status: taxonomyPromiseStatus, value: taxonomyPromiseValue }
    ] = promiseResults;

    const { nutritions, pendingNutrition: existingNutritions } = getNutritionsAndPending(
      nutritionPromiseValue.data,
      'NUTRITION',
      nutritionPromiseStatus
    );

    const { comments } = getNutritionsAndPending(pendingCommentPromiseValue, 'COMMENT', pendingCommentPromiseStatus);
    const { taxonomy } = getNutritionsAndPending(taxonomyPromiseValue, 'TAXONOMY', taxonomyPromiseStatus);
    let pendingNutrition = { ...existingNutritions };
    if (comments && comments.length) {
      const [{ comment, status }] = comments;
      pendingNutrition = { ...JSON.parse(comment), status };
    }

    if (attributePromiseStatus === 'fulfilled') {
      success.push({
        ...attributePromiseValue.data,
        nutritions,
        pendingNutrition,
        pendingNutritions: comments,
        taxonomy
      });
    } else {
      failed.push(supc);
    }
    index++;
  }

  return { success, failed };
}

const makePromissedForMassPfas = changesList => {
  return _.map(changesList, (obj, key) => {
    let attributes = [];
    _.forEach(obj, (selected, attrId) => {
      attributes.push({ attrId, selected });
    });
    return postRequest(`/items/${key}/pfas`, { attributes });
  });
};

async function saveMultiplePfas(changesList, itemsList) {
  const failed = [];
  let itemList = [...itemsList];

  // get all the promisses related saving mass pfass
  const promiseArray = makePromissedForMassPfas(changesList);

  const results = await Promise.allSettled(promiseArray);
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const res of results) {
    // const supc = _.get(data[index], 'supc', null);
    const { status, value } = res;
    const isPromissArray = Array.isArray(value);
    let isAllPromissesResolved = false;
    if (isPromissArray) {
      // eslint-disable-next-line no-await-in-loop
      const pfasUpdateResults = await Promise.allSettled(value);
    }
    const {
      data: { supc, attributes }
    } = value;
    if ((status === 'fulfilled' && !isPromissArray) || (isPromissArray && isAllPromissesResolved)) {
      itemList = [...updateSingleItemDetailsFromTheList(itemList, 'supc', supc, attributes, 'pfasAttributes')];
    } else {
      failed.push(supc);
    }
    index++;
  }

  return { failed, itemList };
}

function* submitMassPfasUpdateAsync({ list, changesList }) {
  try {
    const { failed, itemList } = yield saveMultiplePfas(changesList, list);

    if (!_.isEmpty(failed)) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Couldn't update attributes of the following items: ${_.join(failed, ', ')}`,
          className: 'error',
          message: 'UPDATING ATTRIBUTES FAILED'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Successfully updated attributes!',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }

    yield put({ type: SUBMITMASSPFASCHANGES.SUCCESS, payload: { itemList } });
  } catch (error) {
    yield put({ type: SUBMITMASSPFASCHANGES.FAILURE });
  }
}

async function saveMultipleFsma(itemsList, username) {
  const failed = [];
  const promises = [];

  _.forEach(itemsList, item => {
    if (item.shouldUpdate) {
      const postData = {
        // hardcoded attrID
        attributes: [{ attrId: 1, selected: [item.fsma === 'Yes' ? 1 : 2] }],
        username
      };

      promises.push(postRequest(`/items/${item.supc}/fsma`, postData));
    }
  });

  const fsmaResults = await Promise.allSettled(promises);

  _.forEach(fsmaResults, (res, index) => {
    const item = itemsList[index];
    if (res.status != 'fulfilled') {
      failed.push(item.supc);
    }
  });

  return failed;
}

function* massSaveFSMAChanges({ itemListWithFSMAInfo, username }) {
  try {
    const failed = yield saveMultipleFsma(itemListWithFSMAInfo, username);
    if (!_.isEmpty(failed)) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Couldn't update attributes of the following items: ${_.join(failed, ', ')}`,
          className: 'error',
          message: 'UPDATING ATTRIBUTES FAILED'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Successfully updated FSMA!',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }

    yield put({ type: MASSSAVEFSMACHANGES.SUCCESS });
  } catch (error) {
    yield put({ type: MASSSAVEFSMACHANGES.FAILURE });
  }
}

function* watchMassImageUploadDataAsync() {
  yield takeLatest(RETRIEVEMASSIMAGEUPLOADDATA.REQUEST, getMassImageUploadDataAsync);
  yield takeLatest(MASSIMAGEUPLOADSUBMIT.REQUEST, uploadMassImagesAsync);
  yield takeLatest(VALIDATEMASSUPLOADIMAGES.REQUEST, validateMassImagesAsync);
  yield takeLatest(CHECKTIMEOUTFORIMAGES.REQUEST, checkTimeOutAsync);
}

function* watchAttributeMassEditAsync() {
  yield takeLatest(ALLBUSINESSCENTERS.REQUEST, getAllBusinessCentersAsync);
  yield takeLatest(ATTRIBUTEMASSEDITLIST.REQUEST, getAttributeMassEditListAsync);
  yield takeLatest(ATTRIBUTESLIST.REQUEST, getAttributesAndPicklistValuesAsync);
  yield takeLatest(MASSUPDATEATTRIBUTES.REQUEST, massUpdateAttributesAsync);
}

function* watchPfasMassEditAsync() {
  yield takeLatest(ALLBUSINESSCENTERSPFAS.REQUEST, getAllBusinessCentersAsync);
  yield takeLatest(PFASMASSEDITLIST.REQUEST, getPfasMassEditListAsync);
  yield takeLatest(SUBMITMASSPFASCHANGES.REQUEST, submitMassPfasUpdateAsync);
}

function* watchFsmaMassEditAsync() {
  yield takeLatest(MASSSAVEFSMACHANGES.REQUEST, massSaveFSMAChanges);
}

export { watchMassImageUploadDataAsync, watchAttributeMassEditAsync, watchPfasMassEditAsync, watchFsmaMassEditAsync };
