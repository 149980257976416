import React from 'react';
import { Chart } from 'react-google-charts';
import { regulatoryComplianceCategories } from '../util/Data';
import _ from 'lodash';

class RegulatoryComplianceBarChartByCriteria extends React.Component {
  rcData = {};

  calculate() {
    const { summaryItems } = this.props;

    const data = [];
    data.push(['Criteria', 'Complete', 'Incomplete', { role: 'annotation' }]);

    _.forEach(regulatoryComplianceCategories, rcCat => {
      this.rcData[rcCat.caption] = {
        scoreField: rcCat.scoreField,
        criteria: rcCat.caption,
        completed: 0,
        incomplete: 0
      };
    });

    summaryItems.forEach(item => {
      _.forEach(regulatoryComplianceCategories, rcCat => {
        if (item.prm) {
          if (item.prm[rcCat.scoreField] != undefined) {
            if (item.prm[rcCat.scoreField] === '1') {
              this.rcData[rcCat.caption].completed++;
            } else {
              this.rcData[rcCat.caption].incomplete++;
            }
          }
        }
      });
    });

    _.forEach(this.rcData, item => {
      data.push([item.criteria, item.completed, item.incomplete, '']);
    });
    return data;
  }

  render() {
    const { filterGraph, paramTypes } = this.props;
    const calculatedValues = this.calculate();

    if (!(calculatedValues.length > 1)) {
      return <div className></div>;
    }

    const height = 300;
    let groupWidth = 50;
    let width = 350;
    if (calculatedValues.length > 15) {
      width = 600;
      if (calculatedValues.length > 50) {
        groupWidth = 95;
        width = 1000;
      }
    }

    return (
      <div className="brand-chart-wrapper" style={{ height: `${height}px`, width: `${width}px` }}>
        <div className="brand-chart-title">REGULATORY COMPLIANCE BY SECTION</div>
        <div className="brand-chart">
          <Chart
            chartType="BarChart"
            data={calculatedValues}
            legendToggle
            options={{
              width,
              height,
              legend: 'none',
              bar: { groupWidth: `${groupWidth}%`, groupHeight: `${groupWidth}%` },
              isStacked: true,
              colors: ['#92bb88', '#e9705f'],
              backgroundColor: '#f9f6f8',
              tooltip: {
                textStyle: {
                  color: '#7b8c92'
                }
              },
              hAxis: {
                textStyle: {
                  color: '#7b8c92'
                },
                baselineColor: '#7b8c92'
              },
              vAxis: {
                textStyle: {
                  color: '#7b8c92',
                  fontSize: 9
                },
                baselineColor: '#7b8c92'
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row, column } = chartWrapper.getChart().getSelection()[0];
                  const dataRow = row + 1;
                  const criteria = calculatedValues[dataRow][0];
                  const complete = column === 1 ? '1' : '0';
                  const ele = _.find(regulatoryComplianceCategories, item => item.caption === criteria);
                  filterGraph([{ param: ele.scoreField, val: complete }], [...paramTypes, ...[ele.scoreField]]);
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
export default RegulatoryComplianceBarChartByCriteria;
