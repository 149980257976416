import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import {
  getAveragePercentageOfAttributeCompletion,
  getAveragePercentageOfImageCompletion,
  getAverageFeaturesAndBenifitsCompletion,
  getCompletionOfPercentageStyleColor,
  getCompletionText,
  getAveragePercentageOfRegulatoryComplianceCompletion,
  getIsNonFoodItem
} from '../lib/helpers';
class ItemScore extends React.Component {
  getOverallAverage = (
    isNonFoodItem,
    isPacker,
    attributeCompletionPercentage,
    imageCompletionPercentage,
    fnbCompletionPercentage,
    regulatoryComplianceCompletionPercentage
  ) => {
    if (isPacker) {
      return regulatoryComplianceCompletionPercentage;
    } else if (isNonFoodItem) {
      return Math.round((attributeCompletionPercentage + imageCompletionPercentage + fnbCompletionPercentage) / 3);
    } else {
      return Math.round(
        (attributeCompletionPercentage +
          imageCompletionPercentage +
          fnbCompletionPercentage +
          regulatoryComplianceCompletionPercentage) /
          4
      );
    }
  };

  render() {
    let { supc, summaryItems, isPacker } = this.props;
    const supcScores = _.find(summaryItems, item => {
      return item.supc === supc;
    });

    if (!supcScores) {
      return <div></div>;
    }

    const isNonFoodItem = getIsNonFoodItem(supcScores.prm);

    let attributeCompletionPercentage = 0,
      imageCompletionPercentage = 0,
      fnbCompletionPercentage = 0,
      regulatoryComplianceCompletionPercentage = 0;
    try {
      regulatoryComplianceCompletionPercentage = getAveragePercentageOfRegulatoryComplianceCompletion(supcScores.prm);
    } catch (error) {}
    try {
      attributeCompletionPercentage = getAveragePercentageOfAttributeCompletion(supcScores.dim.attr.at);
    } catch (e) {}
    try {
      fnbCompletionPercentage = getAverageFeaturesAndBenifitsCompletion(supcScores.prm);
    } catch (e) {}
    try {
      imageCompletionPercentage = getAveragePercentageOfImageCompletion(supcScores.prm);
    } catch (e) {}
    let isAttributionCompleteText = getCompletionText(attributeCompletionPercentage);
    let isFnbCompleteText = getCompletionText(fnbCompletionPercentage);
    let isImgCompleteText = getCompletionText(imageCompletionPercentage);
    let isRegulatoryComplianceCompleteText = getCompletionText(regulatoryComplianceCompletionPercentage);

    // since we only have FSMA for the regulatory compliance scorecard
    let overallAverage = this.getOverallAverage(
      isNonFoodItem,
      isPacker,
      attributeCompletionPercentage,
      imageCompletionPercentage,
      fnbCompletionPercentage,
      regulatoryComplianceCompletionPercentage
    );

    let overallAverageCompletionText = getCompletionText(overallAverage);

    return (
      <div className="list-item-scores">
        <Tooltip
          className="list-item-score overall"
          title={
            <div style={{ paddingLeft: '10px' }}>
              Overall: {overallAverageCompletionText} ({overallAverage}%)
            </div>
          }
        >
          <div className={`${getCompletionOfPercentageStyleColor(overallAverage)}`}>{overallAverage}%</div>
        </Tooltip>

        {/* Attribution */}
        {isPacker ? (
          <div className="list-item-score-hidden"></div>
        ) : (
          <Tooltip
            className="list-item-score"
            title={
              <div style={{ paddingLeft: '10px' }}>
                Attribution: {isAttributionCompleteText} ({attributeCompletionPercentage}%)
              </div>
            }
          >
            <div className={`${getCompletionOfPercentageStyleColor(attributeCompletionPercentage)}`}>
              {attributeCompletionPercentage}%
            </div>
          </Tooltip>
        )}

        {/* Fnb */}
        {isPacker ? (
          <div className="list-item-score-hidden"></div>
        ) : (
          <Tooltip
            className="list-item-score"
            title={
              <div style={{ paddingLeft: '10px' }}>
                Fnb: {isFnbCompleteText} ({fnbCompletionPercentage}%)
              </div>
            }
          >
            <div className={`${getCompletionOfPercentageStyleColor(fnbCompletionPercentage)}`}>
              {fnbCompletionPercentage}%
            </div>
          </Tooltip>
        )}

        {/* Image */}
        {isPacker ? (
          <div className="list-item-score-hidden"></div>
        ) : (
          <Tooltip
            className="list-item-score"
            title={
              <div style={{ paddingLeft: '10px' }}>
                Image: {isImgCompleteText} ({imageCompletionPercentage}%)
              </div>
            }
          >
            <div className={`${getCompletionOfPercentageStyleColor(imageCompletionPercentage)}`}>
              {imageCompletionPercentage}%
            </div>
          </Tooltip>
        )}

        {/* Regulatory Compliance: */}
        {/* remove this conditional rendering once PFAS is added into scorecard */}
        {isNonFoodItem ? (
          <div className="list-item-score-hidden"></div>
        ) : (
          <Tooltip
            className="list-item-score"
            title={
              <div style={{ paddingLeft: '10px' }}>
                Regulatory Compliance: {isRegulatoryComplianceCompleteText} ({regulatoryComplianceCompletionPercentage}
                %)
              </div>
            }
          >
            <div className={`${getCompletionOfPercentageStyleColor(regulatoryComplianceCompletionPercentage)}`}>
              {regulatoryComplianceCompletionPercentage}%
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default ItemScore;
