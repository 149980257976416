import React, { useState, useEffect } from 'react';
import { Button, Modal, Select, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import openNotification from 'components/Notification';
import * as actionCreators from '../../actions';
import ReactResizeDetector from 'react-resize-detector';

export default function FsmaMassEditModal({ selectedItems }) {
  const windowHeight = window.innerHeight;
  const dispatch = useDispatch();
  const { toggleFsmaMassEditModal, fetchFsmaRequest, saveFsmaChangesList, massSaveFsmaChanges } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const showModal = useSelector(state => _.get(state.massUpdate.fsma, 'showModal', false));
  const itemList = useSelector(state => _.get(state.massUpdate.fsma, 'itemList', []));
  const isLoading = useSelector(state => _.get(state.massUpdate.fsma, 'isLoading', true));
  const username = useSelector(state => _.get(state.user.data, 'username', ''));
  const [applyToAll, setApplyToAll] = useState('');
  const [disableSelect, setDisableSelect] = useState(true);

  const { Option } = Select;

  useEffect(() => {
    if (showModal === true) {
      setApplyToAll('');
      fetchFsmaRequest(selectedItems);
    }
  }, [showModal]);

  useEffect(() => {
    _.forEach(itemList, item => {
      if (!(item.gdsn === 'Y' || item.isNonFoodItem)) {
        setDisableSelect(false);
      }
    });
  }, [itemList]);

  const handleFSMAChange = (newValue, row, index) => {
    const updatedData = [...itemList];
    updatedData[index].fsma = newValue;
    if (updatedData[index].fsma != updatedData[index].fsmaCopy) {
      updatedData[index].shouldUpdate = true;
    } else {
      updatedData[index].shouldUpdate = false;
    }
    saveFsmaChangesList(updatedData);
  };

  const handleApplyToAll = newValue => {
    const updatedData = [...itemList];
    _.forEach(updatedData, (item, index) => {
      if (!(item.gdsn === 'Y' || item.isNonFoodItem)) {
        item.fsma = newValue;
        if (item.fsma != item.fsmaCopy) {
          item.shouldUpdate = true;
        } else {
          item.shouldUpdate = false;
        }
      }
    });
    setApplyToAll(newValue);
    saveFsmaChangesList(updatedData);
  };

  const closeModal = () => {
    toggleFsmaMassEditModal();
  };

  const renderModalBody = () => {
    const antIcon = <LoadingOutlined className="attr-mass-main-loader" spin />;

    const columns = [
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc'
      },
      {
        title: 'Description',
        dataIndex: 'materialDescription',
        key: 'materialDescription',
        render: text => text?.toUpperCase()
      },
      {
        title: 'Brand',
        dataIndex: 'brandName',
        key: 'brandName'
      },
      {
        title: 'MPC',
        dataIndex: 'manufactProdCode',
        key: 'manufactProdCode'
      },
      {
        title: 'GDSN',
        dataIndex: 'gdsn',
        key: 'gdsn'
      },
      {
        title: 'FSMA 204',
        dataIndex: 'fsma',
        key: 'fsma',
        render: (value, row, index) => (
          <Select
            className={`input-field`}
            style={{ width: 120 }}
            value={value}
            onChange={newValue => handleFSMAChange(newValue, row, index)}
            disabled={row.gdsn === 'Y' || row.isNonFoodItem}
          >
            <Option value="Yes">YES</Option>
            <Option value="No">NO</Option>
          </Select>
        )
      }
    ];

    if (isLoading) {
      return <Spin indicator={antIcon} />;
    } else {
      return (
        <div className="attr-mass-modal-body">
          <ReactResizeDetector
            handleWidth
            handleHeight
            render={({ width, height }) => (
              <div className="grid-panel items-list-table items-list-table-wrapper">
                <Table
                  className="items-list-tbl"
                  columns={columns}
                  dataSource={_.map(itemList, item => ({ ...item, key: item.supc }))}
                  size="small"
                  pagination={false}
                />
              </div>
            )}
          />
        </div>
      );
    }
  };

  const renderModalHeader = () => {
    if (isLoading) {
      return <div />;
    } else {
      return (
        <div className="fsma-modal-header">
          <div>
            <div className="bread-crum-caption">Apply to All</div>
            <Select
              className={`input-field`}
              style={{ width: 120 }}
              value={applyToAll}
              onChange={newValue => handleApplyToAll(newValue)}
              disabled={disableSelect}
            >
              <Option value="Yes">YES</Option>
              <Option value="No">NO</Option>
            </Select>
          </div>
        </div>
      );
    }
  };

  const renderFooterButtons = disabled => {
    if (isLoading) {
      return <div className="attr-mass-modal-footer" />;
    } else {
      return (
        <div className="attr-mass-modal-footer">
          <div></div>
          <div>
            <Button
              key="submit"
              className={disabled ? 'footer-btns submit-btn disabled' : 'footer-btns submit-btn'}
              onClick={() => {
                massSaveFsmaChanges(itemList, username);
              }}
              disabled={disabled}
            >
              SUBMIT CHANGES
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      className="attr-mass-edit-modal"
      visible={showModal}
      maskClosable={false}
      closable={!isLoading}
      onCancel={closeModal}
      width={'99%'}
      bodyStyle={{
        height: `${windowHeight - 73}px`,
        padding: '16px'
      }}
      footer={renderFooterButtons(disableSelect)}
    >
      {renderModalHeader()}
      {renderModalBody()}
    </Modal>
  );
}
